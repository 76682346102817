import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box, 
  Button, 
  CircularProgress, 
  Divider, 
  IconButton, 
  LinearProgress, 
  Skeleton, 
  Tooltip, 
  Typography 
} from "@mui/material";
import React, { useRef, useState, useCallback } from "react";
import { DeleteForever, EditNote, ExpandMore, Folder } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { 
  httpOnCreateLatex, 
  httpOnGetJobDescription, 
  httpOnDeleteResume, 
  httpOnGetTailored, 
  httpOnPutTailoredResume, 
  httpOnGetResume, 
  parseFormValidationError,
  IValidationError
} from "../Services/Http";
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import ResumeForm, { IResumeFormRef } from "../Components/ResumeForm/ResumeForm";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import ResumeTitle from "../Components/ResumeTitle";
import ConfirmDialogBox from "../Components/ConfirmDialogBox";
import UserPreferences from "../Components/UserPreferences";
import { IUserPreferences } from "../Services/Http/interface";
import ResumeFormError from "../Components/ResumeForm/ResumeFormError";
import { stickySubheaderStyles } from "../Components/ResumeForm/styles";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;



// src/pages/TailoredResumeEdit.js
export default function TailoredResumeEdit() {

  const [fileName, setFileName] = useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [jobCompany, setJobCompany] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<IValidationError[]>([]);
  const [tailoredResume, setTailoredResume] = useState<any | null>(null);
  const [resume, setResume] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // eslint-disable-next-line 
  const [latexB62Id, setLatexB62Id] = React.useState<string>("");
  // eslint-disable-next-line 
  const [resumeB62Id, setResumeB62Id] = React.useState<string>("");
  // eslint-disable-next-line 
  const [generationProgress, setGenerationProgress] = useState<number>(0);
  const [generateInProgress, setGenerateInProgress] = useState<boolean>(false);
  // eslint-disable-next-line 
  const [generateCompleted, setGenerateCompleted] = useState<boolean>(false);
  //const [generationProgressMessage, setGenerationProgressMessage] = useState<string>("");

  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = useState<boolean>(false);

  const [preferences, setPreferences] = useState<IUserPreferences | null>(null);

  const [removeResumeInProgress, setRemoveResumeInProgress] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams<{ tailoredB62Id: string; jobB62Id: string; resumeB62Id: string }>();
  const { userId } = useUser();
  const dispatch = useDispatch();
  const formRef = useRef<IResumeFormRef>(null);
  const submitButtonText = 'Save and generate PDF file';

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/folder`);


  // Fetch Tailored Resume, Resume, and Job Description data
  React.useEffect(() => {
    setIsLoading(true);
    httpOnGetTailored(params.tailoredB62Id!, userId)
      .then((res: any) => {
        const tailored = res.data;
        setTailoredResume(tailored);
        if (debugLogs) console.log("tailored", tailored);

        setTimeout(() => {
          setIsLoading(false);
        }, 3000) // give time for the form to render, and prevent submit while it is still rendering
      })
      .catch((e) => {
        setIsLoading(false);
        displayError('Sorry, something went wrong while fetching tailored resume data.');
      });

    setErrorMessage('');
    setErrorMessages([]);

    // Fetch Resume Data
    httpOnGetResume(params.resumeB62Id!, userId)
      .then((res: any) => {
        const resume = res.data;
        setFileName(resume.file_name);
        setResumeUpdatedAt(formatUpdatedAt(resume.created_at));
        setResume(resume);
      })
      .catch((e: any) => {
        // Optionally handle the error
      });

    // Fetch Job Description Data
    httpOnGetJobDescription(params.jobB62Id!, userId)
      .then((res: any) => {
        const jb = res.data;
        const title = jb?.analysis?.job_title?.title || jb?.job_title_details?.title || jb?.job_details?.titles?.[0] || "";
        setJobTitle(title);
        const company = jb?.job_details?.company || jb?.job_title_details?.industry || "";
        setJobCompany(company);
      })
      .catch((e: any) => {
        // Optionally handle the error
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tailoredB62Id, params.jobB62Id, params.resumeB62Id, userId]);

  // /app/${params.resumeB62Id}/job/${jobB62Id}/tailored-resume/${tailoredB62Id}

  const displayError = useCallback((errorMessage: string, messages?: IValidationError[]) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
    setErrorMessages(messages || []);
    setGenerateInProgress(false);
  }, [dispatch]);
  

  const steps = {
    started: {
      progress: 0,
      message: 'Processing'
    },
    latex: {
      progress: 65,
      message: 'Structuring Resume Document'
    },
    pdf: {
      progress: 80,
      message: 'Generating Resume PDF Resume'
    },
    done: {
      progress: 100,
      message: 'Done, Please see Resume PDF Resume in newly opened tab'
    }
  }

  const setProgressStep = (step: any) => {
    //setGenerationProgress(step.progress);
    //setGenerationProgressMessage(step.message);
  }

  const saveAndGeneratePdf = async (tailoredResumeDetails: any) => {

    const updatedTailoredResume = {
      ...tailoredResume,
      resume_details: tailoredResumeDetails
    }

    setTailoredResume(updatedTailoredResume);

    setProgressStep(steps.started);

    setErrorMessage('');
    setErrorMessages([]);

    setGenerateCompleted(false);
    setGenerateInProgress(true);
    setGenerationProgress(0);

    
    setProgressStep(steps.latex);

    const tailoredB62Id = params.tailoredB62Id!;

    try {
      debugLogs && console.log('httpOnPutTailoredResume')
      // eslint-disable-next-line 
      const res: any = await httpOnPutTailoredResume(tailoredB62Id, updatedTailoredResume, userId);
      
    } catch (error: any) {

      const { title, messages } = parseFormValidationError(error);  
      displayError(title, messages)
      return;
    }

    

    const templateB62Id = null; // use default template, override if templateB62Id is provided
    let latexB62Id: string = '';
    try {
      debugLogs && console.log('httpOnCreateLatex')

      const res: any = await httpOnCreateLatex(tailoredB62Id, templateB62Id, preferences, userId);

      latexB62Id = res.data.base62_id;

      setLatexB62Id(latexB62Id);
    } catch (e) {
      displayError('Sorry, something went wrong while structuring the resume document.')
      return;
    }


    setTimeout(() => {
      setGenerateInProgress(false);
      setGenerateCompleted(true);

      navigate(`/app/${params.resumeB62Id}/job/${params.jobB62Id}/tailored-resume/${tailoredB62Id}/latex/${latexB62Id}`);
    }, 1500);

  }

  const removeResume = async (resumeB62Id: string) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    navigate(`/app`)
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${params.resumeB62Id}/edit`);

  return (
    <Box pt={3}>

      <Box sx={stickySubheaderStyles}>

        <Box pb={3} sx={{'display': 'flex', flexWrap: 'wrap' }}>

          <Box sx={{'display': 'flex', flexWrap: 'wrap', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{'display': 'flex', flexWrap: 'wrap' }}>
              <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
              <Box sx={{'display': 'flex'}}>
                <IconButton color={'error'} onClick={() => setRemoveResumeDialogOpen(true)} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
              </Box>
              { removeResumeInProgress && <CircularProgress sx={{
                marginTop: '10px',
                width: '20px !important', height: '20px !important'}}/>
              }
              <Box sx={{'display': 'flex'}}>
                {/* <Button variant="outlined" endIcon={<Edit/>}
                  onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !resumeB62Id}>
                  Edit original resume text.
                </Button> */}
                <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                  <span>
                    <IconButton color={'primary'}
                      onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                      <EditNote/>
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
                <span>
                  <IconButton color={'primary'}
                      onClick={() => {navigateToResourcesView(params.resumeB62Id!)}} disabled={generateInProgress || removeResumeInProgress}><Folder/></IconButton>
                </span>
              </Tooltip>
              </Box>
            </Box>

            <Box sx={{'display': 'flex'}}>
              <Box sx={{'display': 'flex', 'align-items': 'center', flexWrap: 'wrap'}}>
                <Typography>{jobTitle}</Typography>
                {!jobTitle && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
                <Typography> &nbsp;&#x2022;&nbsp; </Typography>
                {!jobCompany && <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />}
                <Typography color="grey">{jobCompany}</Typography>
              </Box>
              {/* <Box sx={{'display': 'flex'}}>
                <IconButton color={'error'} onClick={() => removeResume(params.resumeB62Id!)} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
              </Box>
              { removeResumeInProgress && <CircularProgress sx={{
                marginTop: '10px',
                width: '20px !important', height: '20px !important'}}/>
              }
              <Box sx={{'display': 'flex'}}>
                <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                  <span>
                  <IconButton color={'primary'}
                    onClick={() => editResume(resumeB62Id)} disabled={generateInProgress || removeResumeInProgress || !params.resumeB62Id}>
                    <EditNote/>
                  </IconButton>
                  </span>
                </Tooltip>
              </Box> */}
            </Box>
          </Box>

          <Box pt={2} sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>

              <Box pr={2}>
                <UserPreferences isFormViewMode={true} onChange={setPreferences} />
              </Box>

              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                // startIcon={<Download />}
                disabled={isLoading || generateInProgress || removeResumeInProgress || generateInProgress}
                onClick={() => formRef.current?.submit()}
              >
                {submitButtonText}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* <Box pb={3}>
          { pdfData && <iframe src={pdfData['presigned_url']} width="100%" height="80vh"></iframe> }
        </Box> */}

        {/* <Box pb={3}>
          <Textarea
            aria-label="Additional comments textarea"
            placeholder="Additional instructions or comments to be considered (Optional)"
            maxRows={7}
            sx={{
              'width': '50%',
              'minHeight': '48px',
              'maxHeight': '280px',
            }}
            onChange={(event: any) => setAdditionalInstructions(event.target.value)}
          />
        </Box> */}

        { (isLoading || generateInProgress) && <Box>
          <LinearProgress sx={{marginTop: '-4px'}}/>
        </Box>}

        <ResumeFormError errorMessage={errorMessage}
            errorMessages={errorMessages}
            scrollToElementByPath={formRef?.current?.scrollToElementByPath}/>
      </Box>

      <ResumeForm
        data={tailoredResume && tailoredResume['resume_details']}
        diffOriginData={resume && resume['resume_details']}
        onDataChange={(data) => {saveAndGeneratePdf({...tailoredResume['resume_details'], ...data});}}
        disabled={ isLoading || generateInProgress || removeResumeInProgress}
        ref={formRef}
        hideSubmitButton={true}
        submitText={submitButtonText}
        submitInProgress={generateInProgress}
        showDiff={true}
      />


      {/* <Box pb={3}>
        <Button
          variant="contained"
          disabled={saveInProgress}
          onClick={() => handleSave()}
          >
          Save
        </Button>
      </Box> */}

      <FeedbackSidePopup></FeedbackSidePopup>

      <ConfirmDialogBox
        title="Please confirm"
        message="Please confirm you want to delete this resume!"
        open={removeResumeDialogOpen}
        primaryButton={{ label: 'Delete', onClick: () => {removeResume(params.resumeB62Id!);setRemoveResumeDialogOpen(false);} }}
        secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
        fullWidth
        onClose={() => setRemoveResumeDialogOpen(false)}
      />

      <Box pt={1} pb={6}>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Changes made
          </AccordionSummary>
          <AccordionDetails>
            
            <Box>
              {tailoredResume?.resume_details?.changes_made?.map((item: any) => <Box p={1}>
                <Typography sx={{fontWeight: 'bold'}}>{item?.reasoning}</Typography>
                <Typography>{item?.description}</Typography>
                <Divider sx={{ pt: 1 }} />
              </Box>)}
            </Box>

          </AccordionDetails>
        </Accordion>
      </Box>

    </Box>
  );
}



