// src/pages/Login.tsx

import React from 'react';
import {
  Box,
  Container,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrimaryTextInput from '../../Components/PrimaryTextInput';
import ConfirmDialogBox from '../../Components/ConfirmDialogBox';
import ImgLogo from '../../Assets/logo-net-white-on-blue-medium.png';
import ImgSignin from '../../Assets/ImgSignin.png';
import SnackAlert from '../../Components/SnackAlert';
import { awsOnUserSignIn } from '../../Services/Aws/User';
import { onUserSaveAction } from '../../Redux';
import Button from '../../Components/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface ErrorProps {
  emailError: string;
  passwordError: string;
}

type Severity = 'error' | 'info' | 'warning' | 'success';

interface AlertProps {
  open: boolean;
  message: string;
  severity: Severity;
  duration: number | undefined;
}
// eslint-disable-next-line 
const emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const Login: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<ErrorProps>({
    emailError: '',
    passwordError: '',
  });
  const [dialogBoxOpen, setDialogBoxOpen] = React.useState<boolean>(false);
  const [alertState, setAlertState] = React.useState<AlertProps>({
    open: false,
    message: '',
    severity: 'success',
    duration: 3000,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);


  const onChangeEmail = (value: string): void => {
    setError({ ...error, emailError: '' });
    setEmail(value);
  };

  const onChangePassword = (value: string): void => {
    setError({ ...error, passwordError: '' });
    setPassword(value);
  };

  const onValidate = (): boolean => {
    if (email.length === 0) {
      setError({ ...error, emailError: 'Required!' });
      return false;
    } else if (!emailRegex.test(email)) {
      setError({ ...error, emailError: 'Invalid Email!' });
      return false;
    } else if (password.length === 0) {
      setError({ ...error, passwordError: 'Required!' });
      return false;
    } else {
      return true;
    }
  };

  const onSignIn = (event: React.FormEvent): void => {
    event.preventDefault();
    if (onValidate()) {
      setLoading(true);
      awsOnUserSignIn(email, password)
        .then((userData: any) => {
          if (userData && userData.name) {
            setLoading(false);
            dispatch(onUserSaveAction(userData));
            navigate('/app');
          } else {
            throw new Error('Incomplete user data');
          }
        })
        .catch((err: any) => {
          setLoading(false);
          if (err && err.name === 'UserNotConfirmedException') {
            setDialogBoxOpen(true);
          } else if (err && err.name === 'NotAuthorizedException') {
            setError({ ...error, passwordError: err.message });
          } else if (err && err.name === 'UserNotFoundException') {
            setError({ ...error, passwordError: 'Invalid login or password' });
          } else {
            setAlertState({
              ...alertState,
              open: true,
              message: 'Something went wrong!',
              duration: 3000,
              severity: 'error',
            });
          }
        });
    }
  };

  const onCloseDialogBox = (): void => {
    setDialogBoxOpen(false);
  };

  const onCloseAlert = (): void => {
    setAlertState({ ...alertState, open: false });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onRedirectToSignUp = () => {
    navigate('/signup');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.secondary.light} 90%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          borderRadius: 4,
          boxShadow: 5,
          overflow: 'hidden',
          width: '100%',
          maxWidth: 900,
          m: 2,
        }}
      >
        {/* Left side with image */}
        <Box
          sx={{
            flex: 1,
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            justifyContent: 'center',
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
            color: theme.palette.primary.contrastText,
            p: 4,
          }}
        >
          <Box sx={{ textAlign: 'center', maxWidth: 300 }}>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
              Welcome Back!
            </Typography>
            <Typography variant="body1">
              Sign in to continue accessing your personalized resume builder.
            </Typography>
            <Box
              component="img"
              src={ImgSignin}
              alt="Sign In"
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: 300,
                mt: 4,
                mx: 'auto',
              }}
            />
          </Box>
        </Box>

        {/* Right side with form */}
        <Box
          sx={{
            flex: 1,
            p: { xs: 4, md: 6 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            component="img"
            src={ImgLogo}
            alt="Logo"
            sx={{
              pb: 2,
              maxWidth: 150,
            }}
          />
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
            Sign In
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{
              mt: 1,
              width: '100%',
              maxWidth: 400,
            }}
          >
            <PrimaryTextInput
              label="Email"
              name="email"
              required
              value={email}
              onChange={onChangeEmail}
              error={error.emailError !== ''}
              helperText={error.emailError}
              fullWidth
              sx={{ mb: 2 }}
            />
            <PrimaryTextInput
              label="Password"
              name="password"
              required
              value={password}
              onChange={onChangePassword}
              type={showPassword ? 'text' : 'password'}
              error={error.passwordError !== ''}
              helperText={error.passwordError}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                loading={loading}
                type="primary"
                label="Log In"
                onClick={onSignIn}
                sx={{
                  mt: 2,
                  width: '100%',
                  backgroundColor: theme.button.primary.backgroundColor,
                  color: theme.button.primary.color,
                  fontSize: theme.typography.pxToRem(16),
                  textTransform: theme.button.primary.textTransform,
                  boxShadow: theme.button.primary.boxShadow,
                  '&:hover': {
                    backgroundColor: theme.button.primary['&:hover']?.backgroundColor,
                    boxShadow: theme.button.primary['&:hover']?.boxShadow,
                  },
                }}
              />
            </Box>

          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">
              Don't have an account?{' '}
              <Link
                onClick={onRedirectToSignUp}
                variant="body2"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
      {/* Dialogs and Alerts */}
      <ConfirmDialogBox
        title="Account Verification"
        message="Please check your email and click the verification link. Thank you!"
        open={dialogBoxOpen}
        primaryButton={{ label: 'OK', onClick: onCloseDialogBox }}
        fullWidth
      />
      <SnackAlert
        open={alertState.open}
        severity={alertState.severity}
        message={alertState.message}
        duration={alertState.duration}
        onClose={onCloseAlert}
      />
    </Box>
  );
};

export default Login;
