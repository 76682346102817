import { Box, Button, CircularProgress, IconButton, LinearProgress,  Menu, MenuItem, MenuList,  Tooltip, Typography, FormControlLabel, Switch, Alert } from "@mui/material";
import React, { useEffect } from "react";
import FileUpload from "../Components/FileUpload";
import Textarea from "../Components/TextArea";
import { ArrowBack, AutoAwesome, DeleteForever, Settings } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { httpOnCreateTailored,  httpOnGetResumes, httpOnDeleteResume, httpOnSaveJobDescription, httpPollJobDescriptionExtracted, httpPollResumeExtracted, httpPollTailoredExtracted, iDetailedStatusContextWithCallback } from "../Services/Http";
import useUser from "../Hooks/useUser";
//import FeedbackForm from "../Components/FeedbackForm";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux/Alert/Actions";
import { formatUpdatedAt } from "../Services/Util/dateFormat";
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import LlmAdvancedSettings, { ILlmAdvancedSettings } from "../Components/LlmAdvancedSettings";
import ResumeCard from "../Components/ResumeCard";
import FeedbackSidePopup from "../Components/FeedbackSidePopup";


const debugLogs = process.env.REACT_APP_DEBUG_LOGS;

const UNPIN = 'select-resume';

const textFieldStyle: SxProps<Theme> = {
  height: '100%',
  '& .MuiInputBase-input': {
    fontFamily: '"Courier New", Courier, monospace',
    lineHeight: '1.5'
  },
};


// src/pages/Home.js
export default function Home() {

  const [fileName, setFileName] = React.useState<string>("");
  const [resumeUpdatedAt, setResumeUpdatedAt] = React.useState<string>("");

  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [resumes, setResumes] = React.useState<any[]>([]);

  const [generateInProgress, setGenerateInProgress] = React.useState<boolean>(false);

  const [generationProgress, setGenerationProgress] = React.useState<number>(0);
  const [generationProgressMessage, setGenerationProgressMessage] = React.useState<string>("");

  const [jobDescription, setJobDescription] = React.useState<string>("");
  //const [additionalInstructions, setAdditionalInstructions] = React.useState<string>("");

  const [removeResumeInProgress, setRemoveResumeInProgress] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const params = useParams();

  const { userId, isAdmin } = useUser();

  const dispatch = useDispatch();

  const [isLoadingResumes, setIsLoadingResumes] = React.useState<boolean>(false);

  const hasResumeId = params.resumeB62Id!== UNPIN && params.resumeB62Id!== undefined;

  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState<boolean>(false);

  // eslint-disable-next-line
  const  [showAdvancedToolsForAdmin, setShowAdvancedToolsForAdmin] = React.useState<boolean>(isAdmin);

  const [resumeDetails, setResumeDetails] = React.useState<any>(null);

  // eslint-disable-next-line
  const [settings, setSettings] = React.useState<ILlmAdvancedSettings>();

  const menuAnchorEl = React.useRef<any>(null);

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleDragOver = (e: any) => {
    setMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener('dragover', handleDragOver);
    
    return () => {
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  const handleUploadCompleted = (resumeB62Id: string, resumeUpdatedAt: string) => {
    setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    navigateToResume(resumeB62Id);
  }

  const handleUploadCompletedWithResumeValidationError = (resumeB62Id: string, resumeUpdatedAt: string) => {
    // setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    editResume(resumeB62Id);
  }

  const selectResume = (resume: any) => {
    const resumeB62Id = resume.base62_id;
    setResumeDetails(resume);
    navigateToResume(resumeB62Id);
  }

  useEffect(() => {
    if (resumeDetails) {  
      const resumeUpdatedAt = resumeDetails.updated_at;
      setFileName(resumeDetails.file_name);
      setResumeUpdatedAt(formatUpdatedAt(resumeUpdatedAt));
    }
  }, [resumeDetails]);

  const navigateToResume = (resumeB62Id: string) => {
    navigate(`/app/${resumeB62Id}`);
  }

  const navigateUnpin = () => {
    navigate(`/app/${UNPIN}`);
  }

  //const [jobTitle, setJobTitle] = React.useState<string>("");
  const [jobTitleOnlyMode, setJobTitleOnlyMode] = React.useState<boolean>(false);

  const theme = useTheme();

  React.useEffect(() => {
    setResumeDetails(null);

    setIsLoadingResumes(true);

    httpOnGetResumes(userId).then((res: any) => {
      setIsLoadingResumes(false);

      const resumes = res.data.results;
      setResumes(resumes);
      if (resumes && resumes.length) {
        
        if (params.resumeB62Id === UNPIN) {
          setMenuOpen(true);
        } else if (params.resumeB62Id) {
          const resume = resumes.find((resume: any) => resume.base62_id === params.resumeB62Id)
          if (resume) {
            setResumeDetails(resume)
          } else {
            navigate(`/app`);
          }
        } else {

          const resume = resumes[0];
          const resumeB62Id = resume.base62_id;
          setResumeDetails(resume);
          navigateToResume(resumeB62Id);

        }
      } else {
        if (params.resumeB62Id) {
          navigate(`/app`);
        }

        setMenuOpen(false);
      } 
    }).catch((e) => {
      setIsLoadingResumes(false);

      if (!e || e.status === 404) {
        setResumes([]);
      }
    })

    setGenerationProgressMessage('');
    setErrorMessage('');
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.resumeB62Id]);

  const handleMenuClickRemoveResume = async (resume: any) => {

    try {
      await removeResume(resume.base62_id)
      setResumes(resumes.filter((r: any) => r.base62_id!== resume.base62_id));
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }

  }

  const displayError = (errorMessage: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
    setGenerateInProgress(false);
  }

  const steps = {
    started: {
      progress: 0,
      message: 'Firing up the ResumeFactory'
    },
    resumeResume: {
      progress: 2,
      message: 'Processing your uploaded resume'
    },
    jobDescription: {
      progress: 4,
      message: 'Processing job description'
    },
    tailoredResume: {
      progress: 24,
      message: 'Creating your Tailored Resume',
      nSubSteps: 10,
      progressWhenDone: 98
    },
    // latex: {
    //   progress: 65,
    //   message: 'Structuring Resume Document'
    // },
    // pdf: {
    //   progress: 80,
    //   message: 'Generating Resume PDF Resume'
    // },
    done: {
      progress: 100,
      message: 'Done, Please see Resume PDF Resume in newly opened tab'
    }
  }

  function getPercentage(nthSubStep: number, progress: number, progressWhenDone: number, nSubSteps: number) {
    const progressPerStep = (progressWhenDone - progress) / (nSubSteps + 1)
    const result = progress + (nthSubStep + 1) * progressPerStep
    return result;
  }

  const setProgressStep = (step: any, isSubStep: boolean = false, nthSubStep: number = 0) => {


    let progress = step.progress
    if (isSubStep) {
      progress = getPercentage(nthSubStep, step.progress, step.progressWhenDone, step.nSubSteps);
    }
    setGenerationProgress(progress);

    // Use this code to set the progress bar correct percentages
    // const stepName = getKeyByValue(steps, step)
    // const trackingEvent = {
    //   timestamp: new Date(), stepName, nthSubStep, progress
    // }
    // console.log(trackingEvent);
    // // @ts-ignore
    // if (!window['_trackingEvents']) {window._trackingEvents = [];}
    // // @ts-ignore
    // window._trackingEvents.push(trackingEvent)
    // function getKeyByValue(object: any, value: string) {
    //   for (const [key, val] of Object.entries(object)) {
    //       if (val === value) return key;
    //   }
    // }

    setGenerationProgressMessage(step.message);
  }

  const generateResume = async () => {

    setProgressStep(steps.started);

    setErrorMessage('');

    setGenerateInProgress(true);
    setGenerationProgress(0);

    let jobB62Id: string = '';
    try {
      debugLogs && console.log('httpOnSaveJobDescription')
      const res: any = await httpOnSaveJobDescription(jobDescription, jobTitleOnlyMode, userId)
      jobB62Id = res.data.base62_id;
    } catch (e) {
      displayError('Sorry, something went wrong processing the job description.')
      return;
    }

    setProgressStep(steps.resumeResume);

    let resumeB62Id = null;
    try {
      debugLogs && console.log('httpPollResumeExtracted')
      const res: any = await httpPollResumeExtracted(params.resumeB62Id!, userId)
      resumeB62Id = res.data.base62_id;
    } catch (e) {
      displayError('Sorry, something went wrong while processing the uploaded resume file.')
      return;
    }

    setProgressStep(steps.jobDescription);

    try {
      debugLogs && console.log('httpPollJobDescriptionExtracted')
      // eslint-disable-next-line 
      const res: any = await httpPollJobDescriptionExtracted(jobB62Id, userId)
    } catch (e) {
      displayError('Sorry, something went wrong while processing the job description.')
      return;
    }

    setProgressStep(steps.tailoredResume);

    let tailoredB62Id: string = '';
    try {
      debugLogs && console.log('httpOnCreateTailored')
      const res: any = await httpOnCreateTailored(resumeB62Id, jobB62Id, userId)
      tailoredB62Id = res.data.base62_id;
    } catch (e: any) {
      let msg = 'Sorry, something went wrong while creating the tailored resume.'
      if (e?.data?.statusCode === 429) {
        msg = `Sorry, you have reached the maximum number of resumes that can be created in a 24 hour period.
        Please don't hesitate to contact our support if you need to create more resumes.

        Error message: ${e?.data?.message}
        `
      }

      displayError(msg)
      return;
    }

    try {
      debugLogs && console.log('httpPollTailoredExtracted')
      // eslint-disable-next-line 
      const res: any = await httpPollTailoredExtracted(tailoredB62Id, userId, 3000,
        {status: '', statusChangedCounter: 0, detailedStatusCallback} as iDetailedStatusContextWithCallback)
    } catch (e) {
      displayError('Sorry, something went wrong while creating the tailored resume.')
      return;
    }

    setProgressStep(steps.done);

    setTimeout(() => {
      setGenerateInProgress(false);

      navigate(`/app/${params.resumeB62Id}/job/${jobB62Id}/tailored-resume/${tailoredB62Id}`);
    }, 2000);

  }

  const detailedStatusCallback = (status: string, statusChangedCounter: number) => {
    debugLogs && console.log(`httpPollTailoredExtracted detailedStatusCallback status: ${status}`)

    setProgressStep(steps.tailoredResume, true, statusChangedCounter);

    if (status) {
      setGenerationProgressMessage(status);
    }

  };

  const removeResume = async (resumeB62Id: string, shouldNavigateToHome: boolean = false) => {
    setRemoveResumeInProgress(true);
    try {
      await httpOnDeleteResume(resumeB62Id, userId)
    } catch (e) {
      setRemoveResumeInProgress(false);
      return;
    }
    setRemoveResumeInProgress(false);
    if (shouldNavigateToHome) {
      navigate('/app');
    }
  }

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/edit`);

  const isResumeStatusFailed = () => { // has validation errors
    return resumeDetails?.status ===	"Failed";
  }

  return (
    <Box pt={3}>

      <Box pb={3}>

        <Box>
          

          {/* <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} /> */}

          { hasResumeId && 
          <Box>
            <Box sx={{p: 1, pb: 2}}>
              <Tooltip title={'Use another resume'} placement="top" arrow>
                  {/* <span>
                    <IconButton color={'primary'} onClick={() => navigateUnpin()} disabled={generateInProgress || removeResumeInProgress}><Cancel/></IconButton>
                  </span> */}
                  <Button variant='outlined' color={'primary'}
                    onClick={() => navigateUnpin()} disabled={generateInProgress || removeResumeInProgress}
                    startIcon={<ArrowBack/>}>
                    Use another resume
                  </Button>
              </Tooltip>
            </Box>
          </Box>
          }
          { !hasResumeId &&
          <Box>
            <Box display={(!hasResumeId && resumes.length === 0 && isLoadingResumes) ? 'unset' : 'none'}>
              {/* <Typography pb={'12.5px'}>
                Loading...
                <CircularProgress sx={{
                    marginBottom: '-4px', marginRight: '8px', marginLeft: '8px',
                    width: '20px !important', height: '20px !important'}}/>
              </Typography> */}
              <LinearProgress sx={{marginTop: '-4px'}}/>

            </Box>

          </Box>
          }

          { isResumeStatusFailed() &&
            <Alert severity='error'>
              <Typography variant="body1">
                Selected resume has validation errors, please edit and fix errors to be able to proceed with the next steps.
              </Typography>
              <Button
                  onClick={() => editResume(params.resumeB62Id!)}
                  color='error'
                  variant="outlined"
                  sx={{mt: 1}}
                >
                  Edit Resume
                </Button>
            </Alert>
          }

          <Box sx={{ display: 'flex', justifyContent:'start', flexWrap: 'wrap', alignItems:'stretch' }}>

            <Box sx={{p: 1, width: theme.spacing(48)}}>

              { !hasResumeId && 
              <Box>

                <FileUpload
                  onUploadCompleted={handleUploadCompleted}
                  onSetFileName={(fileName: string) => setFileName(fileName)}
                  onUploadCompletedWithResumeValidationError={handleUploadCompletedWithResumeValidationError}
                ></FileUpload>

              <Box display={(!hasResumeId && resumes.length > 0) ? 'block' : 'none'} pt={2}>
                <Button
                  id="demo-positioned-button"
                  aria-controls={menuOpen ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? 'true' : undefined}
                  onClick={handleMenuClick}
                  ref={menuAnchorEl}
                >
                  Choose Previously Uploaded Resume
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={menuAnchorEl.current}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  sx={{height: theme.spacing(40)}}
                >
                  {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography pl={2} >Choose Previously Uploaded Resume</Typography>
                    <IconButton onClick={(event) => {handleMenuClose()}}><Close/></IconButton>
                  </Box>
                  <Divider /> */}
                  <MenuList>
                    {resumes.map(resume  => (
                      <MenuItem onClick={() => {selectResume(resume); handleMenuClose();}} key={resume.base62_id} >
                        {/* <ListItemIcon>
                          <Check />
                        </ListItemIcon> */}
                        {resume.file_name}
                        <Typography> &nbsp;&#x2022;&nbsp; </Typography>
                        <Typography color="grey">{formatUpdatedAt(resume.updated_at)}</Typography>

                        <IconButton color={'error'} sx={{marginLeft: 'auto', marginRight: '-10px'}}
                          onClick={(event) => {event.stopPropagation();handleMenuClickRemoveResume(resume)}} disabled={generateInProgress || removeResumeInProgress}><DeleteForever/></IconButton>
                      </MenuItem>  
                    ))}
                  </MenuList>
                </Menu>
                </Box>
              </Box>
              }

              { hasResumeId && 
              <ResumeCard
                resumeB62Id={params.resumeB62Id!}
                fileName={fileName}
                resumeUpdatedAt={resumeUpdatedAt}
                onRemoveResume={(resumeB62Id: string) => removeResume(resumeB62Id, true)}
                removeResumeInProgress={removeResumeInProgress}
                isDisabled={generateInProgress}
                displayNavigateToResources={resumeDetails?.tailor_count > 0}
                isResumeStatusFailed={isResumeStatusFailed()}
              ></ResumeCard>
              }

            </Box>

            <Box sx={{ flexGrow: 0.05, p:1 }}></Box>

            <Box sx={{ flexGrow: 1, p:1 }}>
              <form style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>

                <Box style={{ height: '100%', flexGrow: 1 }}>
                  { showAdvancedToolsForAdmin && <FormControlLabel
                    sx={{ paddingLeft: '80px' }}
                    control={<Switch value={jobTitleOnlyMode} onChange={(event) => setJobTitleOnlyMode(event.target.checked)}/>}
                    label="Job title only" />
                  }
                  <Textarea
                    aria-label="job description textarea"
                    placeholder={jobTitleOnlyMode ? "Please paste the Job Title here": "Please paste the Job Description here"}
                    maxRows={5}
                    disabled={generateInProgress || isResumeStatusFailed()}
                    sx={{
                      'width': '100%',
                      'height': '100%',
                      // 'minHeight': jobTitleOnlyMode ? '100px' : '220px',
                      // 'maxHeight': jobTitleOnlyMode ? '100px' : '220px',
                      // # 'height': jobTitleOnlyMode ? '220px' : '480px',
                      ...textFieldStyle
                    }}
                    onChange={(event: any) => setJobDescription(event.target.value)}
                  />
                </Box>


                <Box pl={6.5} sx={{
                  marginBottom: '-72px', // buttons height
                  pt: 4, }}>
                  <Button
                    variant="contained"
                    endIcon={<AutoAwesome />}
                    disabled={!hasResumeId || !jobDescription || generateInProgress}
                    onClick={generateResume}
                    sx={{...theme.button.primary,
                      width: {md: 300},
                      mt: 0,
                      fontSize: '16px',
                    }}
                    >
                    Generate Tailored Resume
                  </Button>
                </Box>

              </form>

            </Box>
          </Box>

          <Box pt={9}></Box>


          { showAdvancedToolsForAdmin &&
          <Box pb={3}>
            {!showAdvancedSettings && <Button endIcon={<Settings/>} onClick={() => setShowAdvancedSettings(true)}>Show advanced settings </Button>}
            {showAdvancedSettings && <LlmAdvancedSettings onChange={setSettings} firstOpen={true}/>}
          </Box>
          }

        </Box>
      </Box>

      { generateInProgress && <Box>
        <LinearProgress variant="determinate" value={generationProgress} />
      </Box> }

      { errorMessage ?
        <Box pb={2} pt={1}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box> :
        <Box pb={2} pt={1}>
          <Typography color={'primary'}>
          { generateInProgress && <CircularProgress sx={{
            marginBottom: '-3px', marginRight: '8px',
            width: '20px !important', height: '20px !important'}}/> }
            {generationProgressMessage}
          </Typography>
        </Box>
      }

    <Box pb={3}></Box>

    <FeedbackSidePopup></FeedbackSidePopup>

    </Box>
  );
}